
.theory-container {
  ::v-deep .el-button--text {
    color: rgba(17, 34, 216, 1);
  }
  padding: 30px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  .theory-container-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    ::v-deep .el-breadcrumb__item:last-child .el-breadcrumb__inner {
      color: rgb(0, 0, 0);
      font-weight: bold;
    }
    ::v-deep .el-breadcrumb__item {
      .el-breadcrumb__inner.is-link{
        font-weight: 400;
        color: #606266;
      }
    }
    .training-main {
      .training-title {
        margin-left: 4px;
        font-weight: bold;
      }
    }
  }
  .el-table {
    flex: 1;
    margin-top: 26px;
    ::v-deep .el-table th.el-table__cell {
      background: rgba(245, 245, 251, 1);
    }
  }
  .pages-center {
    margin-top: 20px;
    text-align: center;
  }
}
